import { SelectedProjectProvider } from "components/project/SelectedProjectProvider";
import { HoveredProjectProvider } from "components/project/hovered-project/HoveredProjectContext";
import { SearchedProjectsProvider } from "components/project/searched-projects/SearchedProjectsContext";
import { SelectedContextualFeatureProvider } from "components/layers/SelectedContextualFeatureProvider";
import { DrawLayerProvider } from "components/drawLayer/DrawLayerProvider";
import { ProjectProvider } from "components/project/ProjectProvider";
import { VectorGridProvider } from "components/map/VectorGridProvider";
import { LayersProvider } from "components/layers/LayersProvider";
import { PanelsProvider } from "components/panel/PanelsProvider";
import { DateRangeMapFilterProvider } from "./Map/map-controls/DateRangeMapFilterProvider";
import { SideBar } from "./Sidebar/Sidebar";
import { Panel } from "./Panel/Panel";
import { Map } from "./Map/Map";
import { MyOrganisationsProjectsMapFilterProvider } from "./Panel/MyOrganisation/MyOrganisationsProjectsMapFilterProvider";
import { WatchlistProvider } from "components/watchlist/WatchlistContext";
import { SavedSearchProvider } from "components/project/searched-projects/SavedSearchContext";
import { MessageOfTheDay } from "./MessageOfTheDay";

const motd = {
  enabled: true,
  motdKey: "upgrade-2024-10-14",
  title: "IMPORTANT - MONDAY 14TH OCTOBER - FORWARD WORKS VIEWER UPGRADE",
  content: (
    <>
      <p>
        The FWV infrastructure will be upgraded on Monday 14th October. Whilst the system will still be usable for viewing and searching, if you make any changes to the data in the system, they will not be saved. There will be minimal, if any, downtime envisaged. You can continue to use the FWV throughout the day.
      </p>
      Changes include:
      <ul>
        <li>manually creating a project</li>
        <li>editing any projects in the FWV</li>
        <li>add or editing any project boundaries in the FWV</li>
        <li>creating a saved search</li>
        <li>creating a saved search watchlist</li>
        <li>bookmarking a project</li>
        <li>turning on notifications</li>
      </ul>
      We will let you know when the system is back to normal, we expect this to be Monday 5pm at the very latest.
    </>
  ),
};

export const MainPage = () => (
  <VectorGridProvider>
    <ProjectProvider>
      <SearchedProjectsProvider>
        <HoveredProjectProvider>
          <SelectedProjectProvider>
            <SavedSearchProvider>
              <WatchlistProvider>
                <DrawLayerProvider>
                  <LayersProvider>
                    <SelectedContextualFeatureProvider>
                      <DateRangeMapFilterProvider>
                        <MyOrganisationsProjectsMapFilterProvider>
                          <h1 className="visually-hidden">Main page</h1>
                          <div className="d-flex vh-100 overflow-hidden">
                            <PanelsProvider>
                              <SideBar />
                              <Panel />
                              <Map />
                            </PanelsProvider>
                          </div>
                          <MessageOfTheDay {...motd} />
                        </MyOrganisationsProjectsMapFilterProvider>
                      </DateRangeMapFilterProvider>
                    </SelectedContextualFeatureProvider>
                  </LayersProvider>
                </DrawLayerProvider>
              </WatchlistProvider>
            </SavedSearchProvider>
          </SelectedProjectProvider>
        </HoveredProjectProvider>
      </SearchedProjectsProvider>
    </ProjectProvider>
  </VectorGridProvider>
);
